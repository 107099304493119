export default {
  methods: {
    objDecoder(type, id) {
      let loopObj = {};
      let idVar = "";
      let nameVar = "";
      switch (type) {
        case "O":
          loopObj = this.offices;
          idVar = "officeID";
          nameVar = "officeName";
          break;
        case "W":
          loopObj = this.workStations;
          idVar = "workstationID";
          nameVar = "workstationName";
          break;
        case "U":
          loopObj = this.users;
          idVar = "id";
          nameVar = "fullname";
          break;
      }

      for (let i = 0; i < loopObj.length; i++) {
        if (loopObj[i][idVar] == id) {
          return loopObj[i][nameVar];
        }
      }
      return "";
    },
    async search(wipe = true) {
      let endpoint = "";
      let sendData = "";
      let msg = {};

      if (this.searchFields.inventoryNum != "") {
        this.searchFields.plateClass = "";
        this.searchFields.issueYear = "";
        this.searchFields.siteNumber = "";
        this.searchFields.userId = "";
        endpoint =
          "/inventorysubrange/platesearch/" + this.searchFields.inventoryNum;
      } else {
        endpoint = "/inventorysubrange/search?";
        sendData = {
          workstationID: this.searchFields.workstation,
          plateClass: this.searchFields.plateClass,
          issueYear: this.searchFields.issueYear,
          officeID: this.searchFields.siteNumber,
          countyID: this.countyID,
          userID: this.searchFields.userId,
          statusCodes: this.searchStatusCodes
        };
        Object.keys(sendData).forEach(key => {
          if (sendData[key] != undefined && sendData[key] != "") {
            endpoint += key + "=" + sendData[key] + "&";
          }
        });
      }

      let results = [];

      try {
        const noResults = e => {
          throw e;
        };
        msg = await this.$api.processInventoryGet(endpoint, {
          404: noResults,
          204: noResults
        });
      } catch (e) {
        this.classGrouping = [];
        return false;
      }

      results = msg;
      const classGrouping = {};
      for (let i = 0; i < results.length; i++) {
        if (results[i].remainingQuantity > 0) {
          if (results[i].statusCode == "S") {
            if (
              !(
                results[i].plateClassCode + "-" + results[i].issueYear in
                classGrouping
              )
            ) {
              classGrouping[
                results[i].plateClassCode + "-" + results[i].issueYear
              ] = {
                plateClass: results[i].plateClassCode,
                issueYear: results[i].issueYear,
                incomingQuantity: results[i].remainingQuantity,
                currentQuantity: 0,
                orderedQuantity: 0,
                subRanges: [],
                ordered: []
              };
              classGrouping[
                results[i].plateClassCode + "-" + results[i].issueYear
              ].subRanges.push(results[i]);
            } else {
              classGrouping[
                results[i].plateClassCode + "-" + results[i].issueYear
              ].incomingQuantity =
                parseInt(
                  classGrouping[
                    results[i].plateClassCode + "-" + results[i].issueYear
                  ].incomingQuantity
                ) + parseInt(results[i].remainingQuantity);
              classGrouping[
                results[i].plateClassCode + "-" + results[i].issueYear
              ].subRanges.push(results[i]);
            }
          } else {
            if (
              !(
                results[i].plateClassCode + "-" + results[i].issueYear in
                classGrouping
              )
            ) {
              classGrouping[
                results[i].plateClassCode + "-" + results[i].issueYear
              ] = {
                plateClass: results[i].plateClassCode,
                issueYear: results[i].issueYear,
                currentQuantity: results[i].remainingQuantity,
                incomingQuantity: 0,
                orderedQuantity: 0,
                subRanges: [],
                ordered: []
              };
              classGrouping[
                results[i].plateClassCode + "-" + results[i].issueYear
              ].subRanges.push(results[i]);
            } else {
              classGrouping[
                results[i].plateClassCode + "-" + results[i].issueYear
              ].currentQuantity =
                parseInt(
                  classGrouping[
                    results[i].plateClassCode + "-" + results[i].issueYear
                  ].currentQuantity
                ) + parseInt(results[i].remainingQuantity);
              classGrouping[
                results[i].plateClassCode + "-" + results[i].issueYear
              ].subRanges.push(results[i]);
            }
          }
        }
      }
      if (this.searchFields.inventoryNum == "") {
        let orderedInventory = {};
        endpoint = "/inventory/inventoryrequest/search?";
        sendData = {
          statusCode: "P",
          officeID: this.searchFields.siteNumber
        };
        Object.keys(sendData).forEach(key => {
          if (sendData[key] != undefined && sendData[key] != "") {
            endpoint += key + "=" + sendData[key] + "&";
          }
        });

        try {
          orderedInventory = await this.$api.processInventoryGet(endpoint);
        } catch (e) {
          this.classGrouping = [];
          return false;
        }

        for (let i = 0; i < orderedInventory.length; i++) {
          if (
            (this.searchFields.plateClass == "" ||
              this.searchFields.plateClass ==
                orderedInventory[i].plateClassCode) &&
            (this.searchFields.issueYear == "" ||
              this.searchFields.issueYear == orderedInventory[i].issueYear)
          ) {
            if (
              !(
                orderedInventory[i].plateClassCode +
                  "-" +
                  orderedInventory[i].issueYear in
                classGrouping
              )
            ) {
              classGrouping[
                orderedInventory[i].plateClassCode +
                  "-" +
                  orderedInventory[i].issueYear
              ] = {
                plateClass: orderedInventory[i].plateClassCode,
                issueYear: orderedInventory[i].issueYear,
                incomingQuantity: 0,
                currentQuantity: 0,
                orderedQuantity: orderedInventory[i].requestQuantity,
                subRanges: [],
                ordered: []
              };
              classGrouping[
                orderedInventory[i].plateClassCode +
                  "-" +
                  orderedInventory[i].issueYear
              ].ordered.push(orderedInventory[i]);
            } else {
              classGrouping[
                orderedInventory[i].plateClassCode +
                  "-" +
                  orderedInventory[i].issueYear
              ].orderedQuantity =
                parseInt(
                  classGrouping[
                    orderedInventory[i].plateClassCode +
                      "-" +
                      orderedInventory[i].issueYear
                  ].orderedQuantity
                ) + parseInt(orderedInventory[i].requestQuantity);
              classGrouping[
                orderedInventory[i].plateClassCode +
                  "-" +
                  orderedInventory[i].issueYear
              ].ordered.push(orderedInventory[i]);
            }
          }
        }
      }
      const minmax = await this.$api.getInventoryLevel(
        this.$store.getters.officeId
      );

      for (let i = 0; i < minmax.length; i++) {
        if (
          classGrouping[minmax[i].plateClassCode + "-" + minmax[i].issueYear] ==
          undefined
        )
          continue;

        const blankArr = [0, undefined, ""];
        const singleRecord =
          classGrouping[minmax[i].plateClassCode + "-" + minmax[i].issueYear];

        //let the state values override county values
        if (blankArr.includes(minmax[i].stateMaxInvQty)) {
          Object.assign(singleRecord, {
            max: minmax[i].reccomendedMaxInvQty
          });
        }

        if (blankArr.includes(minmax[i].stateMinInvQty)) {
          Object.assign(singleRecord, {
            min: minmax[i].reccomendedMinInvQty
          });
        }

        if (blankArr.includes(singleRecord.min)) {
          Object.assign(singleRecord, {
            min: minmax[i].stateMinInvQty
          });
        }

        if (blankArr.includes(singleRecord.max)) {
          Object.assign(singleRecord, {
            max: minmax[i].stateMaxInvQty
          });
        }

        Object.assign(singleRecord, {
          yearId: minmax[i].issueYearID,
          report: minmax[i].reportInd,
          inventoryLevelID: minmax[i].inventoryLevelID,
          autoOrder: minmax[i].activeInd,
          approved: minmax[i].approvedInd
        });
      }

      const tempArr = [];
      Object.keys(classGrouping).forEach(key => {
        if (
          classGrouping[key].currentQuantity > 0 ||
          classGrouping[key].incomingQuantity > 0 ||
          classGrouping[key].orderedQuantity > 0
        ) {
          tempArr.push({
            plateClass: classGrouping[key].plateClass,
            issueYear: classGrouping[key].issueYear,
            currentQuantity: classGrouping[key].currentQuantity,
            incomingQuantity: classGrouping[key].incomingQuantity,
            orderedQuantity: classGrouping[key].orderedQuantity,
            subRanges: classGrouping[key].subRanges,
            ordered: classGrouping[key].ordered,
            max: classGrouping[key].max,
            min: classGrouping[key].min,
            yearId: classGrouping[key].yearId,
            report: classGrouping[key].report,
            autoOrder: classGrouping[key].autoOrder,
            inventoryLevelID: classGrouping[key].inventoryLevelID,
            approved: classGrouping[key].approved
          });
        }
      });
      this.classGrouping = tempArr;
      if (wipe) {
        this.drillDownLevel = 0;
        this.subRangeList = [];
      }
    },
    classSelect() {
      if (this.searchFields.plateClassIndex === "") {
        this.issueYearArray = "";
        this.searchFields.plateClass = "";
      } else {
        this.searchFields.plateClass =
          this.$store.getters.plateClasses[
            this.searchFields.plateClassIndex - 1
          ].plateClassCode;
        this.issueYearArray = this.$store.getters.plateClasses[
          this.searchFields.plateClassIndex - 1
        ].plateDates.map(plateDate => plateDate.issueYear);
      }
      this.$nextTick(() => {
        this.searchFields.issueYear = "";
      });
    },
    async initalizePage() {
      this.users = await this.$api.getAuthUsers();

      this.offices = await this.$api.getOffices(this.$store.getters.countyId);

      this.countyPlateStatuses = await this.$api.getCountyPlateStatuses();

      this.plateStatuses = await this.$api.getPlateStatuses();

      this.rangeStatuses = await this.$api.getInventoryRangeStatus();

      this.workStations = this.offices[0].workstations;
    }
  }
};
