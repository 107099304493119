<template>
  <div id="statusChange">
    <div v-if="!showEdit">
      <span>
        {{ statusResolve[currentStatus] || currentStatus }}
      </span>
      <v-tooltip :open-delay="toolTipDelay" bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            class="beside"
            color="primary"
            icon
            @click="
              $event.stopPropagation();
              showEdit = true;
            "
          >
            <template slot="default">
              <v-icon>edit</v-icon>
            </template>
          </v-btn>
        </template>
        <span> Change the status of this plate</span>
      </v-tooltip>
    </div>
    <div @click="$event.stopPropagation()" :class="{ invisible: !showEdit }">
      <select @click="$event.stopPropagation()" v-model="selectedStatus">
        <option :value="currentStatus">
          {{ statusResolve[currentStatus] || currentStatus }}
        </option>
        <option v-for="(value, key) in options" :key="key" :value="key">
          {{ value }}
        </option>
      </select>
      <v-tooltip :open-delay="toolTipDelay" bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="currentStatus == selectedStatus"
            fab
            small
            @click="
              $event.stopPropagation();
              saveClicked();
              showEdit = false;
            "
            color="success"
          >
            <v-icon dark>done</v-icon>
          </v-btn>
        </template>

        <span>Save the plate status change</span>
      </v-tooltip>
      <v-tooltip :open-delay="toolTipDelay" bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              $event.stopPropagation();
              showEdit = false;
            "
            fab
            small
            color="error"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
        <span>Close</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    currentStatus: { type: String, default: () => "" },
    statusResolve: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showEdit: false,
      selectedStatus: this.currentStatus
    };
  },
  methods: {
    saveClicked() {
      this.$emit("save", this.selectedStatus);
    }
  },
  computed: {
    ...mapGetters({
      toolTipDelay: "toolTipDelay"
    })
  }
};
</script>

<style scoped lang="scss">
.invisible {
  display: none;
}
#statusChange .green {
  background-color: green;
}

#statusChange select {
  margin: 0;
}

.center {
  justify-content: center;
}

::v-deep .v-btn.beside {
  margin-top: -8px;
}
</style>
