<template>
  <div class="main">
    <div class="panel">
      <current />
    </div>
  </div>
</template>
<script>
import current from "@/components/nonPageComponents/InventoryCurrent";

export default {
  components: {
    current
  }
};
</script>
<style scoped>
.main {
  padding: 15px;
}
</style>
