import jsPDF from "jspdf";
export default {
  methods: {
    async downloadPDF(ref, fileName) {
      this.$root.$emit("setLoading", true);
      try {
        const fullHeight = document.getElementById(ref).offsetHeight;
        const fullWidth = document.getElementById(ref).offsetWidth;
        const pages = Math.ceil(fullHeight / 1200); //number of pages if this were printing on letter
        const images = [];
        const topMarginOffset = 200; //remove blank space at top
        const letterPageInPixels = 1200; //size of 8.5 x 11 but in pixels

        const tempImg = await this.$html2canvas(document.getElementById(ref), {
          height: letterPageInPixels,
          width: fullWidth,
          y: topMarginOffset
        });

        images.push(tempImg);

        for (let i = 1; i < pages; i++) {
          const tempImg = await this.$html2canvas(document.getElementById(ref), {
            height: letterPageInPixels,
            width: fullWidth,
            y: i * letterPageInPixels + topMarginOffset
          });
          images.push(tempImg);
        }

        const height = images[0].height * 1.5; //add space for page margins
        const width = images[0].width * 1.5; //add space for page margins
        const doc = new jsPDF("p", "px", [width, height]);

        for (let i = 0; i < images.length; i++) {
          const imgUrl = await images[i].toDataURL();
          if (i > 0) doc.addPage();
          doc.addImage(imgUrl, "PNG", 0, 0, images[i].width, images[i].height);
        }

        doc.save(fileName + ".pdf");
      } finally {
        this.$root.$emit("setLoading", false);
      }
    }
  }
};
