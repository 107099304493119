<template>
  <div id="inventory">
    <div class="flex-container">
      <div class="inputDiv" id="plateField">
        <v-text-field
          label="Plate Number"
          @keyup.enter="inventorySearch()"
          v-model="searchFields.inventoryNum"
        />
      </div>
      <div class="inputDiv">
        <v-select
          label="Site Number"
          v-model="searchFields.siteNumber"
          :items="offices"
          item-text="officeName"
          item-value="officeID"
        />
      </div>
      <div class="inputDiv">
        <v-select
          v-model="searchFields.workstation"
          label="Workstation"
          :items="workStations"
          item-text="workstationName"
          item-value="workstationID"
        />
      </div>
      <div class="inputDiv">
        <v-select
          label="Class"
          menu-props="auto"
          v-model="searchFields.plateClassIndex"
          @change="classSelect"
          id="class"
          :items="classSelectMap"
        />
      </div>
      <div class="inputDiv">
        <v-select
          label="Issue Year"
          v-model="searchFields.issueYear"
          id="issueYear"
          :items="issueYearArray"
        />
      </div>
      <div class="inputDiv">
        <v-select
          label="User"
          v-model="searchFields.userId"
          :items="users"
          item-text="username"
          item-value="id"
        />
      </div>
      <div class="inputDiv">
        <br />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="inventorySearch()" color="blue" icon>
              <v-icon>search</v-icon>
            </v-btn>
          </template>
          <span>Search</span>
        </v-tooltip>
      </div>

      <div class="inputDiv">
        <br />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="
                clear();
                inventorySearch();
              "
              color="red"
              icon
            >
              <v-icon>close</v-icon>
            </v-btn>
          </template>
          <span>Clear</span>
        </v-tooltip>
      </div>
    </div>

    <div class="legendHold">
      <div class="legend ordered"></div>
      <span>- Pending Orders</span>

      <div class="legend receiving"></div>
      <span>- Ready to Receive</span>
    </div>

    <div class="results bootstrap">
      <div class="table-title">
        <div class="bread">
          <v-breadcrumbs :items="breadCrumbItems" divider=">">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item @click="item.click"
                ><a>{{ item.text }}</a></v-breadcrumbs-item
              >
            </template>
          </v-breadcrumbs>
        </div>

        <div class="export">
          <download-csv
            :data="csvData"
            :fields="csvFields"
            :separator-excel="true"
            name="filename.csv"
          >
            <v-tooltip :open-delay="toolTipDelay" bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="downloadBtn" color="primary" v-on="on">
                  CSV
                </v-btn>
              </template>
              <span> Download a CSV of the data</span>
            </v-tooltip>
          </download-csv>
          <v-tooltip :open-delay="toolTipDelay" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="exportButtonClick('pdf')"
                color="primary"
                class="downloadBtn"
              >
                PDF
              </v-btn>
            </template>
            <span>Download a PDF of the data</span>
          </v-tooltip>
        </div>
      </div>

      <b-table
        ref="table0"
        id="table0"
        :sort-by="'plateClass'"
        v-show="drillDownLevel == 0"
        :sticky-header="stickyTableHeight"
        :no-border-collapse="true"
        selectable
        select-mode="single"
        responsive="sm"
        hover
        :show-empty="true"
        :empty-text="emptyText"
        :fields="tableFields[0]"
        head-variant="light"
        sort-icon-left
        :tbody-tr-class="item => tableRowStyle(0, item)"
        @row-clicked="(item, index) => tableRowClick(0, item, index)"
        :items="classGrouping"
      >
        <template v-slot:cell(plateClass)="data">
          <span>{{ data.item.plateClass }}</span>
          <span
            v-if="
              data.item.plateClass != undefined &&
              plateClassDecodeArray[data.item.plateClass] != undefined
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon></template
              >
              <span>{{
                plateClassDecodeArray[data.item.plateClass]
                  .plateClassDescription
              }}</span>
            </v-tooltip>
          </span>
        </template>
        <template v-slot:cell(currentQuantity)="data">
          {{ data.item.currentQuantity }}
          <div class="progressBar">
            <div
              v-if="
                parseInt(data.item.min) >= 0 || parseInt(data.item.max) >= 0
              "
            >
              <v-progress-linear
                :color="
                  data.item.currentQuantity > data.item.min
                    ? 'success'
                    : 'error'
                "
                :value="(data.item.currentQuantity / data.item.max) * 100"
                :buffer-value="100"
              />
            </div>
          </div>
        </template>
        <template v-slot:cell(minMax)="data">
          <div>
            <template
              v-if="
                parseInt(data.item.min) >= 0 || parseInt(data.item.max) >= 0
              "
            >
              {{ data.item.min }} / {{ data.item.max }}
            </template>
          </div>
        </template>
        <template v-slot:cell(report)="data">
          <template v-if="data.item.report">
            <v-icon color="primary">done</v-icon>
          </template>
        </template>
        <template v-slot:cell(autoOrder)="data">
          <template v-if="data.item.autoOrder">
            <v-icon color="primary">done</v-icon>
          </template>
        </template>
        <template v-slot:cell(approved)="data">
          <template v-if="data.item.approved">
            <v-icon color="primary">done</v-icon>
          </template>
        </template>
        <template v-slot:cell(orderedQuantity)="data">
          <div class="buttonWithNum">
            {{ data.item.orderedQuantity }}
            <v-tooltip :open-delay="toolTipDelay" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  v-if="data.item.orderedQuantity > 0"
                  @click="showOrders(data.item.ordered)"
                  class="beside"
                  icon
                  plain
                  depressed
                >
                  <v-icon color="primary">mdi-inbox-arrow-down</v-icon>
                </v-btn>
              </template>

              <span> Go To Individual Orders </span>
            </v-tooltip>
          </div>
        </template>
      </b-table>

      <b-table
        ref="table1"
        id="table1"
        :sort-by="'rangeBeginNo'"
        v-show="
          drillDownLevel == 1 &&
          (orderedList == undefined || orderedList.length == 0)
        "
        :sticky-header="stickyTableHeight"
        :no-border-collapse="true"
        selectable
        select-mode="single"
        hover
        :fields="tableFields[1]"
        head-variant="light"
        sort-icon-left
        :tbody-tr-class="item => tableRowStyle(1, item)"
        @row-clicked="(item, index) => tableRowClick(1, item, index)"
        :items="subRangeList"
        responsive="sm"
      >
        <template v-slot:cell(plateClassCode)="data">
          <span>{{ data.item.plateClassCode }}</span>
          <span
            v-if="
              data.item.plateClassCode != undefined &&
              plateClassDecodeArray[data.item.plateClassCode] != undefined
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>

              <span>{{
                plateClassDecodeArray[data.item.plateClassCode]
                  .plateClassDescription
              }}</span>
            </v-tooltip>
          </span>
        </template>
        <template v-slot:cell(belongsToID)="data">
          {{ objDecoder(data.item.belongsToType, data.item.belongsToID) }}
        </template>
        <template v-slot:cell(action)="data">
          <span
            v-if="data.item.statusCode === 'S' || data.item.statusCode === 'Y'"
          >
            <v-tooltip :open-delay="toolTipDelay" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click="
                    $event.stopPropagation();
                    showTransferMod(data.item);
                  "
                >
                  <v-icon color="primary">add</v-icon>
                </v-btn>
              </template>
              <span>Mark Inventory Accepted</span>
            </v-tooltip>
          </span>
          <span v-else>
            <v-tooltip :open-delay="toolTipDelay" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click="
                    $event.stopPropagation();
                    showTransferMod(data.item);
                  "
                >
                  <v-icon color="primary">mdi-inbox-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>Transfer/Receive</span>
            </v-tooltip>
          </span>
        </template>
        <template v-slot:cell(statusCode)="data">
          <InventoryStatusChange
            :currentStatus="data.item.statusCode"
            :statusResolve="rangeStatuses"
            :options="rangeStatuses"
            @save="newStatus => changeRangeStatus(newStatus, data.item)"
          />
        </template>
      </b-table>

      <b-table
        id="tableOrder"
        v-if="
          drillDownLevel == 1 &&
          orderedList != undefined &&
          orderedList.length > 0
        "
        :sticky-header="stickyTableHeight"
        :no-border-collapse="true"
        empty-text="No Results"
        :fields="tableFields[3]"
        head-variant="light"
        sort-icon-left
        responsive="sm"
        :items="orderedList"
      >
        <template v-slot:cell(plateClassCode)="data">
          <span>{{ data.item.plateClassCode }}</span>
          <span>
            <v-tooltip
              v-if="
                data.item.plateClassCode != undefined &&
                plateClassDecodeArray[data.item.plateClassCode] != undefined
              "
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              <span>{{
                plateClassDecodeArray[data.item.plateClassCode]
                  .plateClassDescription
              }}</span>
            </v-tooltip>
          </span>
        </template>
        <template v-slot:cell(statusDate)="data">
          {{
            data.item.statusDate.substr(5, 5) +
            "-" +
            data.item.statusDate.substr(0, 4)
          }}
        </template>
      </b-table>

      <b-table
        ref="table2"
        id="table2"
        :sort-by="'plateNum'"
        v-show="drillDownLevel == 2"
        :sticky-header="stickyTableHeight"
        :no-border-collapse="true"
        hover
        selectable
        select-mode="single"
        :fields="tableFields[2]"
        head-variant="light"
        responsive="sm"
        sort-icon-left
        :tbody-tr-class="item => tableRowStyle(2, item)"
        :items="plateList.items"
      >
        <template v-slot:cell(classCode)="data">
          {{ data.item.classCode }}
          <span>
            <v-tooltip
              v-if="
                data.item.classCode != undefined &&
                plateClassDecodeArray[data.item.classCode] != undefined
              "
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon></template
              >
              <span>{{
                plateClassDecodeArray[data.item.classCode].plateClassDescription
              }}</span>
            </v-tooltip>
          </span>
        </template>
        <template v-slot:cell(statusCode)="data">
          <InventoryStatusChange
            :currentStatus="data.item.statusCode"
            :statusResolve="plateStatuses"
            :options="countyPlateStatuses"
            @save="
              newStatus =>
                changePlateStatus(
                  newStatus,
                  data.item.plateNo,
                  plateList.subRangeId
                )
            "
          />
        </template>
        <template v-slot:cell(action)="data">
          <span>
            <v-tooltip open-delay="300" bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="showTransferMod(data.item)">
                  <v-icon color="primary">mdi-inbox-arrow-down</v-icon>
                </v-btn>
              </template>
              <span> Transfer Or Assign </span>
            </v-tooltip>
          </span>
        </template>
      </b-table>

      <div class="flex-container" v-if="addingOrder">
        <div class="inputDiv">
          Class<br />
          <select
            :class="{ invalid: fieldHighlight(orderInputs.plateClass) }"
            v-model="orderInputs.plateClass"
            id="class"
          >
            <option value=""></option>
            <option
              v-for="(value, index) in plateClassesForOrder"
              :value="value.plateClassCode"
              :key="index"
            >
              {{ value.plateClassCode }}
            </option>
          </select>
        </div>
        <div class="inputDiv">
          <v-text-field
            label="Quantity"
            type="number"
            :class="{ invalid: fieldHighlight(orderInputs.quantity) }"
            v-model="orderInputs.quantity"
          />
        </div>
        <div class="inputDiv">
          <v-tooltip :open-delay="toolTipDelay" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="!validateOrderFields"
                color="success"
                fab
                @click="saveOrder"
              >
                <v-icon>done</v-icon>
              </v-btn>
            </template>
            <span>Save</span>
          </v-tooltip>
        </div>
        <div class="inputDiv">
          <v-tooltip :open-delay="toolTipDelay" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="error" fab @click="clearOrder">
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>
      </div>

      <div id="minMax" class="flex-container" v-show="minMaxIsShown">
        <div class="inputDiv">
          Class<br />
          <select
            :class="{ invalid: fieldHighlight(minMaxInputs.plateClass) }"
            v-model="minMaxInputs.plateClass"
            id="class"
          >
            <option value=""></option>
            <option
              v-for="(value, index) in plateClassesForOrder"
              :value="value.plateClassCode"
              :key="index"
            >
              {{ value.plateClassCode }}
            </option>
          </select>
        </div>
        <div class="inputDiv">
          <v-text-field
            label="Min"
            type="number"
            :class="{ invalid: fieldHighlight(minMaxInputs.min) }"
            v-model="minMaxInputs.min"
          />
        </div>
        <div class="inputDiv">
          <v-text-field
            label="Max"
            type="number"
            :class="{ invalid: fieldHighlight(minMaxInputs.max) }"
            v-model="minMaxInputs.max"
          />
        </div>
        <div class="inputDiv">
          <v-checkbox
            v-model="minMaxInputs.report"
            :true-value="true"
            :false-value="false"
            label="Alert"
          />
        </div>
        <div class="inputDiv">
          <v-checkbox
            v-model="minMaxInputs.autoOrder"
            :true-value="true"
            :false-value="false"
            label="Auto"
          />
        </div>
        <div class="inputDiv">
          <v-tooltip :open-delay="toolTipDelay" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="!validateMinMaxFields"
                color="success"
                fab
                @click="saveMinMax"
              >
                <v-icon>done</v-icon>
              </v-btn>
            </template>
            <span>Save</span>
          </v-tooltip>
        </div>
        <div class="inputDiv">
          <v-tooltip :open-delay="toolTipDelay" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" fab color="error" @click="minMaxIsShown = false">
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>
      </div>
      <div class="flex-container bottomButtons">
        <v-tooltip :open-delay="toolTipDelay" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="primary"
              v-show="!addingOrder && !minMaxIsShown"
              @click="showMinMax"
            >
              Auto Order
            </v-btn>
          </template>
          <span> Set A New Point For Auto Ordering</span>
        </v-tooltip>
        <v-tooltip :open-delay="toolTipDelay" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="primary"
              :disabled="
                drillDownLevel > 0 &&
                plateClassesForOrder[orderInputs.plateClass] == undefined
              "
              v-show="!addingOrder && !minMaxIsShown"
              @click="addOrder"
            >
              Manual Order
            </v-btn>
          </template>
          <span>Create A New Order</span>
        </v-tooltip>
      </div>

      <v-dialog v-model="showAssign">
        <v-card class="assignmentModal">
          <div class="topModalSect">
            <div>
              <v-text-field
                :disabled="isReceivingItem"
                @blur="validateEnd"
                @input="updateEnd"
                v-model="assignmentModal.quantity"
                label="Quantity"
              />

              <v-text-field
                :disabled="isReceivingItem"
                @input="validateStart"
                v-model="assignmentModal.start"
                label="Start Plate"
              />

              <v-text-field
                :disabled="isReceivingItem"
                @input="validateEnd"
                v-model="assignmentModal.end"
                label="End Plate"
              />
            </div>
            <span
              :class="{ invisible: modalErrorText.length == 0 }"
              class="redText"
            >
              {{ modalErrorText }}
            </span>
          </div>
          <div class="flex-container">
            <div class="flex-column" v-if="_.has(assignmentModal, 'type')">
              <div class="select">
                <label>Type:</label>
                <br />
                <select v-model="assignmentModal.type">
                  <option
                    v-for="(value, index) in assignmentModalTypes"
                    :key="index"
                    :value="index"
                  >
                    {{ value.type }}
                  </option>
                </select>
              </div>

              <template
                v-if="
                  _.has(assignmentModalTypes[assignmentModal.type], 'options')
                "
              >
                <div class="select">
                  <label>{{
                    assignmentModalTypes[assignmentModal.type].type
                  }}</label>
                  <br />
                  <select
                    v-model="assignmentModalTypes[assignmentModal.type].model"
                  >
                    <option
                      v-for="(value, index) in assignmentModalTypes[
                        assignmentModal.type
                      ].options"
                      :key="
                        assignmentModalTypes[assignmentModal.type].value(
                          index,
                          value
                        )
                      "
                      :value="
                        assignmentModalTypes[assignmentModal.type].value(
                          index,
                          value
                        )
                      "
                    >
                      {{
                        assignmentModalTypes[assignmentModal.type].disp(value)
                      }}
                    </option>
                  </select>
                </div>
              </template>

              <center>
                <v-btn
                  color="blue"
                  @click="
                    inventoryAction(
                      assignmentModalTypes[assignmentModal.type].type,
                      isReceivingItem,
                      assignmentModalTypes[assignmentModal.type].model
                    )
                  "
                >
                  <span v-if="isReceivingItem">Receive</span>
                  <span v-else>Save</span>
                </v-btn>
                <v-btn color="red" @click="showAssign = false">Cancel</v-btn>
              </center>
            </div>
          </div>
          <template
            v-if="_.has(assignmentModalTypes[assignmentModal.type], 'text')"
          >
            <div class="flex-container">
              <h2>{{ assignmentModalTypes[assignmentModal.type].text }}</h2>
            </div>
          </template>
        </v-card>
      </v-dialog>

      <div id="backButton">
        <v-btn v-show="drillDownLevel > 0" @click="drillUp" color="error"
          >Back</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import JsonCSV from "vue-json-csv";
import InventoryStatusChange from "@/components/nonPageComponents/InventoryStatusChange";
import inventoryFuncs from "@/mixins/inventory.mixin.js";
import exportMix from "@/mixins/export.mixin.js";
import { mapGetters } from "vuex";
export default {
  mixins: [inventoryFuncs, exportMix],
  components: {
    InventoryStatusChange,
    "download-csv": JsonCSV
  },
  data() {
    return {
      tableFields: {
        0: [
          {
            key: "plateClass",
            label: "Class",
            sortable: true,
            sortDesc: false
          },
          {
            key: "issueYear",
            sortable: true
          },
          {
            key: "currentQuantity",
            label: "Current Amt.",
            sortable: true
          },
          {
            key: "incomingQuantity",
            label: "Receiving",
            sortable: true
          },
          {
            key: "minMax",
            label: "Min / Max"
          },
          {
            key: "report",
            label: "Alert",
            sortable: true
          },
          {
            key: "autoOrder",
            sortable: true
          },
          {
            key: "approved",
            sortable: true
          },
          {
            key: "orderedQuantity",
            label: "On Order",
            sortable: true
          }
        ],
        1: [
          {
            key: "rangeBeginNo",
            label: "Start #",
            sortable: true
          },
          {
            key: "rangeEndNo",
            label: "End #",
            sortable: true
          },
          {
            key: "plateClassCode",
            label: "Class",
            sortable: false
          },
          {
            key: "issueYear",
            sortable: false
          },
          {
            key: "belongsToID",
            label: "Assigned To",
            sortable: true
          },
          {
            key: "remainingQuantity",
            label: "Quantity",
            sortable: true
          },
          {
            key: "statusCode",
            label: "Status",
            sortable: true
          },
          {
            key: "action",
            label: "Transfer/Receive"
          }
        ],
        2: [
          {
            key: "plateNo",
            label: "Plate #",
            sortable: true
          },
          {
            key: "classCode",
            label: "Class",
            sortable: true
          },
          {
            key: "issueYear",
            label: "Issue Year",
            sortable: true
          },
          {
            key: "statusCode",
            label: "Status",
            sortable: true
          },
          {
            key: "action",
            label: "Transfer"
          }
        ],
        3: [
          {
            key: "plateClassCode",
            label: "Class",
            sortDesc: false,
            sortable: true
          },
          {
            key: "issueYear",
            label: "Year",
            sortable: true
          },
          {
            key: "requestQuantity",
            label: "Qty Ordered",
            sortable: true
          },
          {
            key: "overMaxQuantity",
            label: "Amount Over Max",
            sortable: true
          },
          {
            key: "statusDate",
            sortable: true
          },
          {
            key: "statusCode",
            label: "Status",
            sortable: true
          }
        ]
      },
      assignmentModalTypes: {},
      stickyTableHeight: "calc(-400px + 100vh)",
      issueYearArray: [],
      searchFields: {
        inventoryNum: "",
        siteNumber: "",
        issueYear: "",
        plateClass: "",
        workstation: "",
        plateClassIndex: "",
        userId: ""
      },
      searchStatusCodes: "R,S,Y", //default status codes to search on
      classGrouping: [], //main results obj
      drillDownLevel: 0, //which table to show
      subRangeList: [],
      orderedList: [],
      addingOrder: false,
      orderInputs: {
        issueYear: "",
        issueYearArray: [],
        plateClass: "",
        plateClassIndex: "",
        quantity: ""
      },
      minMaxInputs: {
        classSelected: false,
        plateClass: "",
        issueYear: "",
        yearId: "",
        min: "",
        max: "",
        report: false,
        autoOrder: false
      },
      plateList: {},
      users: [],
      offices: [],
      workStations: [],
      countyPlateStatuses: [], //statuses the county can change to
      plateStatuses: [], //list of all statuses
      rangeStatuses: [], //list of all subRange statuses
      showAssign: false,
      minMaxIsShown: false,
      showReceivingModal: false,
      modalErrorText: "",
      assignmentModal: {
        type: 0,
        site: 0,
        workstation: 0,
        user: 0,
        county: "",
        lineItem: {},
        start: "",
        end: "",
        quantity: "",
        sequence: []
      },
      working: false,
      lastScroll: 0,
      lastSelectedClass: "",
      indexSaver: {
        0: 0,
        1: 0,
        2: 0
      },
      searchingInventory: false
    };
  },

  methods: {
    showSnack() {
      this.$root.$emit("push-alert", "Your request saved successfully.", {
        color: "success"
      });
    },
    async inventorySearch() {
      this.searchingInventory = true;
      try {
        await this.search();
      } finally {
        this.searchingInventory = false;
      }
    },
    async changeRangeStatus(newStatus, subRange) {
      const sendData = {};
      Object.assign(sendData, subRange);
      Object.assign(sendData, {
        statusCode: newStatus
      });
      try {
        this.$root.$emit("setLoading", true);
        await this.$api.changeRangeStatus(sendData);
      } finally {
        this.$root.$emit("setLoading", false);
      }
      this.showSnack();
      this.inventorySearch();
    },
    async changePlateStatus(newStatus, plate, subRangeId) {
      try {
        this.$root.$emit("setLoading", true);
        await this.$api.changePlateStatus(subRangeId, plate, newStatus);
      } finally {
        this.$root.$emit("setLoading", false);
      }
      this.showSnack();
      this.inventorySearch();
    },
    exportButtonClick(type) {
      if (type == "pdf") {
        //have to deal with the order table
        if (
          this.drillDownLevel == 1 &&
          this.orderedList != undefined &&
          this.orderedList.length > 0
        ) {
          this.downloadPDF("tableOrder", "inventory");
        } else {
          this.downloadPDF("table" + this.drillDownLevel, "inventory");
        }
      }
    },
    showMinMax() {
      let currentSelectedItem = null;
      for (let i = 0; i < this.classGrouping.length; i++) {
        if (this.classGrouping[i].plateClass == this.lastSelectedClass) {
          currentSelectedItem = this.classGrouping[i];
          break;
        }
      }
      if (currentSelectedItem != undefined) {
        Object.assign(this.minMaxInputs, currentSelectedItem);
      }
      this.minMaxIsShown = true;
    },
    async saveMinMax() {
      const sendData = {};
      if (!["", undefined].includes(this.minMaxInputs.yearId)) {
        Object.assign(sendData, {
          issueYearID: this.minMaxInputs.yearId
        });
      } else {
        Object.assign(sendData, {
          plateClassCode: this.minMaxInputs.plateClass,
          issueYear: this.minMaxInputs.issueYear
        });
      }
      if (!["", undefined].includes(this.minMaxInputs.inventoryLevelID)) {
        Object.assign(sendData, {
          inventoryLevelID: parseInt(this.minMaxInputs.inventoryLevelID)
        });
      }
      Object.assign(sendData, {
        stateProductTypeID: 10,
        reportInd: this.minMaxInputs.report,
        activeInd: this.minMaxInputs.autoOrder,
        approvedInd: false,
        reccomendedMinInvQty: parseInt(this.minMaxInputs.min),
        reccomendedMaxInvQty: parseInt(this.minMaxInputs.max),
        officeID: parseInt(this.$store.getters.officeId)
      });
      this.$root.$emit("setLoading", true);
      try {
        this.$api.saveInventoryLevel(sendData);
      } finally {
        this.$root.$emit("setLoading", false);
      }
      this.minMaxIsShown = false;
      this.showSnack();
      this.inventorySearch();
    },
    showOrders(items) {
      this.orderedList = items;
      this.lastSelectedClass = items[0].plateClassCode;
      this.clearOrder();
      this.drillDownLevel = 1;
    },
    async saveOrder() {
      const sendData = {
        requestQuantity: parseInt(this.orderInputs.quantity),
        officeID: parseInt(this.$store.getters.officeId),
        stateProductTypeID: 10
      };
      this.$root.$emit("setLoading", true);
      try {
        await this.$api.saveInventoryOrder(
          this.orderInputs.plateClass,
          sendData
        );
      } finally {
        this.$root.$emit("setLoading", false);
      }
      this.addingOrder = false;
      await this.inventorySearch();
      this.showSnack();
      for (let i = 0; i < this.classGrouping.length; i++) {
        if (this.classGrouping[i].plateClass == this.orderInputs.plateClass) {
          this.showOrders(this.classGrouping[i].ordered);
          break;
        }
      }
    },
    addOrder() {
      if (
        this.plateClassesForOrder[this.lastSelectedClass] != undefined &&
        !this.plateClassesForOrder[this.lastSelectedClass].personalizedPlateInd
      )
        this.orderInputs.plateClass = this.lastSelectedClass;
      this.addingOrder = true;
    },
    fieldHighlight(field) {
      return ["", undefined].includes(field);
    },
    clearOrder() {
      Object.assign(this.orderInputs, {
        issueYear: "",
        issueYearArray: [],
        plateClass: "",
        plateClassIndex: "",
        quantity: ""
      });
      this.addingOrder = false;
    },
    clear() {
      Object.assign(this.searchFields, {
        issueYear: "",
        userId: "",
        plateClassIndex: "",
        plateClass: "",
        workstation: "",
        siteNumber: "",
        inventoryNum: ""
      });
    },
    async drillUp() {
      if (this.drillDownLevel > 0) {
        this.orderedList = [];
        this.addingOrder = false;
        this.minMaxIsShown = false;
        this.orderInputs.plateClass = "";
        this.drillDownLevel--;
        await this.$nextTick(() => {
          this.$refs["table" + this.drillDownLevel].selectRow(
            this.indexSaver[this.drillDownLevel]
          );
        });
        setTimeout(() => {
          document
            .querySelector("#table" + this.drillDownLevel + " .table-active")
            .focus();
          document.querySelector(
            "#table" + this.drillDownLevel + ""
          ).scrollTop = document.querySelector(".table-active").offsetTop - 50;
          this.$refs["table" + this.drillDownLevel].clearSelected();
        }, 50);
      }
    },
    tableRowClick(level, item, index) {
      if (item != undefined) {
        this.indexSaver[level] = index;
        this.addingOrder = false;
        this.minMaxIsShown = false;
        switch (level) {
          case 0:
            this.orderInputs.plateClass = item.plateClass;
            if (item.subRanges.length > 0) {
              this.subRangeList = item.subRanges;
              this.drillDownLevel++;
            }
            break;
          case 1:
            this.showPlates(item);
            break;
        }
      }
    },
    tableRowStyle(level, item) {
      if (item != undefined) {
        switch (level) {
          case 0:
            if (item.orderedQuantity > 0) return "ordered";
            if (item.incomingQuantity > 0) return "receiving";
            break;
          case 1:
            if (["S", "Y"].includes(item.statusCode)) return "receiving";
            break;
          case 2:
            if (["S", "Y"].includes(item.statusCode)) return "receiving";
            break;
        }
      }
    },
    async arrowBindHandle(event) {
      switch (event.which) {
        case 40: //down arrow
          if (
            document.activeElement.parentElement.parentElement == undefined ||
            document.activeElement.parentElement.parentElement.id !=
              "table" + this.drillDownLevel
          ) {
            document
              .querySelector(
                "#table" + this.drillDownLevel + " tbody tr:first-child"
              )
              .focus();
            setTimeout(() => {
              document.querySelector(
                "#table" + this.drillDownLevel
              ).parentElement.scrollTop = 0;
            }, 50);
          }
          break;
        case 37: //left arrow
          this.drillUp();
          break;
        case 39: //right arrow
          if (
            document.activeElement.parentElement.parentElement.id ==
            "table" + this.drillDownLevel
          ) {
            document.activeElement.click();
          }
          break;
      }
    },
    async showPlates(item) {
      this.plateList = {};
      this.working = true;
      this.$root.$emit("setLoading", true);
      try {
        this.plateList["items"] = await this.$api.subRangeAvailability(
          item.inventorySubRangeID
        );
        this.plateList["subRangeId"] = item.inventorySubRangeID;
        this.drillDownLevel = 2;
      } finally {
        this.working = false;
        this.$root.$emit("setLoading", false);
      }
    },
    updateEnd() {
      //getting the ending plate number from sequence array by adding the index of the start plate with the quantity and subtracting 1 since it will include the start.
      const startIndex = this.assignmentModal.sequence.indexOf(
        this.assignmentModal.start
      );

      if (startIndex === -1) {
        this.modalErrorText = "The start plate is not in the chosen inventory.";
        return;
      }
      if (this.assignmentModal.quantity == "") {
        this.assignmentModal.end = "";
        return;
      }
      const endIndex =
        this.assignmentModal.sequence.indexOf(this.assignmentModal.start) +
        parseInt(this.assignmentModal.quantity) -
        1;
      if (endIndex + 1 > this.assignmentModal.sequence.length) {
        this.modalErrorText =
          "There are not enough plates in the chosen inventory for this quantity.";
        return;
      }
      this.modalErrorText = "";
      this.assignmentModal.end = this.assignmentModal.sequence[endIndex];
    },
    validateStart() {
      this.assignmentModal.start = this.assignmentModal.start.toUpperCase();
      //check if it is in range and update the end if quantity is set.
      const startIndex = this.assignmentModal.sequence.indexOf(
        this.assignmentModal.start
      );
      if (this.assignmentModal.start != this.assignmentModal.end) {
        if (startIndex === -1) {
          this.modalErrorText =
            "The start plate is not in the chosen inventory.";
          return;
        }
      }
      this.modalErrorText = "";
      this.updateEnd();
    },
    validateEnd() {
      this.assignmentModal.end = this.assignmentModal.end.toUpperCase();
      //check if it is in range and update the quantity if the start and end are both set
      //also recalculate the quantity if they type here or clear it.
      const endIndex = this.assignmentModal.sequence.indexOf(
        this.assignmentModal.end
      );
      const startIndex = this.assignmentModal.sequence.indexOf(
        this.assignmentModal.start
      );
      if (this.assignmentModal.start != this.assignmentModal.end) {
        if (endIndex === -1) {
          this.modalErrorText = "The end plate is not in the chosen inventory.";
          return;
        }
        this.modalErrorText = "";
        if (startIndex === -1) {
          this.assignmentModal.quantity = "";
          return;
        }
      }
      if (startIndex > endIndex) {
        this.modalErrorText =
          "The end inventory must be the same or greater than the start inventory.";
        return;
      }
      this.assignmentModal.quantity = endIndex - startIndex + 1;
      this.modalErrorText = "";
    },
    async showTransferMod(item) {
      this.assignmentModal.site = 0;
      this.assignmentModal.workstation = 0;
      this.assignmentModal.user = 0;
      this.assignmentModal.county = "";
      this.assignmentModal.lineItem = {};
      this.assignmentModal.start = "";
      this.assignmentModal.end = "";
      this.assignmentModal.sequence = [];
      this.modalErrorText = "";

      if (
        this._.has(this.plateList, "subRangeId") &&
        this.drillDownLevel == "2"
      ) {
        this.assignmentModal.sequence = await this.$api.subRangeAvailability(
          this.plateList.subRangeId
        );
        this.assignmentModal.lineItem = item;
        this.assignmentModal.start = item.plateNo;
        this.assignmentModal.end = item.plateNo;
        this.assignmentModal.quantity = 1;
      } else {
        this.assignmentModal.sequence = await this.$api.subRangeAvailability(
          item.inventorySubRangeID
        );
        this.assignmentModal.sequence = this.assignmentModal.sequence.map(
          plate => plate.plateNo
        );
        this.assignmentModal.lineItem = item;
        this.assignmentModal.start = this.assignmentModal.sequence[0];
        this.assignmentModal.end =
          this.assignmentModal.sequence[
            this.assignmentModal.sequence.length - 1
          ];
        this.assignmentModal.quantity = item.remainingQuantity;
      }

      this.changeModalTypesHandler();
      this.showAssign = true;
    },
    inventoryAction(type, receiving = false, id) {
      if (receiving) {
        this.receive(type, id);
      } else {
        this.reassign(type, id);
      }
    },
    async receive(type, id) {
      this.$root.$emit("setLoading", true);
      let endpoint;
      if (this.assignmentModal.lineItem.statusCode === "S") {
        endpoint =
          "/inventory/acceptorder/" +
          this.assignmentModal.lineItem.inventoryRangeID;
      } else if (this.assignmentModal.lineItem.statusCode === "Y") {
        endpoint =
          "/inventory/accepttransfer/" +
          this.assignmentModal.lineItem.inventorySubRangeID;
      }
      switch (type) {
        case "County":
          endpoint += "/" + this.countyID + "/C";
          break;
        case "Site":
          endpoint += "/" + id + "/O";
          break;
        case "User":
          endpoint += "/" + id + "/U";
          break;
        case "Workstation":
          endpoint += "/" + id + "/W";
          break;
      }
      try {
        await this.$api.processInventoryEndpoint(endpoint);
         this.$store.dispatch("setGlobalAlertState", {
          title: "Success",
          description: "Received successfully",
          icon: "success"
        });
        this.showAssign = false;
        this.inventorySearch();
      } finally {
        this.$root.$emit("setLoading", false);
      }
    },
    async reassign(type, id) {
      this.$root.$emit("setLoading", true);

      const item = this.assignmentModal.lineItem;
      let countedQuantity = 0;

      const startIndex = this.assignmentModal.sequence.indexOf(
        this.assignmentModal.start
      );
      const endIndex = this.assignmentModal.sequence.indexOf(
        this.assignmentModal.end
      );
      let error = false;
      if (
        (startIndex !== -1 && endIndex !== -1) ||
        !this._.has(item, "rangeBeginNo")
      ) {
        if (startIndex > endIndex) {
          this.$root.$emit("setLoading", false);
          this.modalErrorText =
            "The end inventory must be the same or greater than the start inventory.";
          error = true;
        } else if (startIndex == endIndex) {
          countedQuantity = 1; //only one plate
        } else {
          //difference in range, add 1 to include the start
          countedQuantity = endIndex - startIndex + 1;
        }
      } else {
        this.$root.$emit("setLoading", false);
        this.modalErrorText =
          "The start and end both have to be contained in the chosen inventory.";
        error = true;
      }

      if (!error) {
        let endpoint = "";
        switch (type) {
          case "Site":
            endpoint = "/inventorysubrange/";
            endpoint +=
              (this.assignmentModal.lineItem.inventorySubRangeID ||
                this.plateList.subRangeId) + "/transfer/";
            endpoint +=
              this.assignmentModal.start +
              "/" +
              this.assignmentModal.end +
              "/" +
              countedQuantity;
            endpoint += "/" + id + "/0/0";
            endpoint += "?keepStatus=true";
            break;
          case "User":
            endpoint = "/inventorysubrange/";
            endpoint +=
              (this.assignmentModal.lineItem.inventorySubRangeID ||
                this.plateList.subRangeId) + "/transfer/";
            endpoint +=
              this.assignmentModal.start +
              "/" +
              this.assignmentModal.end +
              "/" +
              countedQuantity;
            endpoint += "/" + this.$store.getters.officeId + "/" + id + "/0";
            endpoint += "?keepStatus=true";
            break;
          case "Workstation":
            endpoint = "/inventorysubrange/";
            endpoint +=
              (this.assignmentModal.lineItem.inventorySubRangeID ||
                this.plateList.subRangeId) + "/transfer/";
            endpoint +=
              this.assignmentModal.start +
              "/" +
              this.assignmentModal.end +
              "/" +
              countedQuantity;
            endpoint += "/" + this.$store.getters.officeId + "/0/" + id;
            endpoint += "?keepStatus=true";
            break;
          case "County":
            endpoint =
              "/inventory/countytransfer/" +
              (this.assignmentModal.lineItem.inventorySubRangeID ||
                this.plateList.subRangeId);
            endpoint +=
              "/" +
              this.assignmentModal.start +
              "/" +
              this.assignmentModal.end +
              "/" +
              countedQuantity +
              "/" +
              id;
            break;
          default:
            this.$root.$emit("setLoading", false);
            this.modalErrorText = "You must select a type.";
            error = true;
            return false;
        }
        try {
          await this.$api.processInventoryEndpoint(endpoint);
          this.showAssign = false;
           this.$store.dispatch("setGlobalAlertState", {
            title: "Success",
            description: "The re-assign is finished",
            icon: "success"
          });
          this.inventorySearch();
        } finally {
          this.$root.$emit("setLoading", false);
        }
      }
    },
    tableScrollListen() {
      if (
        document.querySelector("#table" + this.drillDownLevel).parentElement
          .scrollTop < this.lastScroll
      ) {
        if (
          document.querySelector("#table" + this.drillDownLevel).parentElement
            .scrollTop < 50
        ) {
          document.querySelector(
            "#table" + this.drillDownLevel
          ).parentElement.scrollTop = 0;
        }
      }
      this.lastScroll = document.querySelector(
        "#table" + this.drillDownLevel
      ).parentElement.scrollTop;
    },
    trBlurHandle(event) {
      this.lastSelectedClass = event.target.cells[0].children[0].innerHTML;
    },
    changeModalTypesHandler() {
      let types = [];
      if (this.isReceivingItem) {
        types.push({
          type: "County",
          text: "Receive and assign to current county."
        });
      }
      types = types.concat([
        {
          type: "Site",
          options: this.offices,
          model: this.assignmentModal.site,
          disp: value => {
            return value.officeName;
          },
          value: (index, value) => {
            return value.officeID;
          }
        },
        {
          type: "User",
          options: this.users,
          model: this.assignmentModal.user,
          disp: value => {
            return value.username;
          },
          value: (index, value) => {
            return value.id;
          }
        },
        {
          type: "Workstation",
          options: this.workStations,
          model: this.assignmentModal.workstation,
          disp: value => {
            return value.workstationName;
          },
          value: (index, value) => {
            return value.workstationID;
          }
        }
      ]);
      if (!this.isReceivingItem) {
        types.push({
          type: "County",
          options: Object.values(this.counties).slice(0, 96), //only show 1-96
          model: this.assignmentModal.county,
          disp: value => {
            return value;
          },
          value: index => {
            return index;
          }
        });
      }
      this.assignmentModalTypes = types;
    }
  },
  async mounted() {
    this.initalizePage();
    await this.inventorySearch();

    window.addEventListener("keydown", this.arrowBindHandle, false);
    const trList = document.querySelectorAll("#table0 tr");
    for (let i = 0; i < trList.length; i++) {
      trList[i].addEventListener("blur", this.trBlurHandle, false);
    }
    for (let i = 0; i < 3; i++) {
      document
        .querySelector("#table" + i)
        .parentElement.addEventListener(
          "scroll",
          this.tableScrollListen,
          false
        );
    }
  },
  destroyed() {
    window.removeEventListener("keydown", this.arrowBindHandle);
    for (let i = 0; i < 3; i++) {
      document
        .querySelector("#table" + i)
        .parentElement.removeEventListener("scroll", this.tableScrollListen);
    }
    const trList = document.querySelectorAll("#table0 tr");
    for (let i = 0; i < trList.length; i++) {
      trList[i].removeEventListener("blur", this.trBlurHandle);
    }
  },
  computed: {
    ...mapGetters({
      countyID: "countyId",
      counties: "counties",
      plateClassArray: "plateClasses",
      toolTipDelay: "toolTipDelay"
    }),
    breadCrumbItems() {
      const items = [
        {
          text: "CLASS",
          click: () => {
            this.drillDownLevel = 0;
            this.orderedList = [];
          }
        }
      ];
      if (this.drillDownLevel > 0 && this.orderedList.length == 0) {
        items.push({
          text: "RANGE",
          click: () => {
            this.drillDownLevel = 1;
          }
        });
      }
      if (this.drillDownLevel > 0 && this.orderedList.length > 0) {
        items.push({
          text: "Order",
          click: () => {
            this.drillDownLevel = 1;
          }
        });
      }
      if (this.drillDownLevel > 1) {
        items.push({
          text: "PLATE",
          click: () => {}
        });
      }
      return items;
    },
    emptyText() {
      return this.searchingInventory
        ? "Loading..."
        : "There are no records to show";
    },
    classSelectMap() {
      return Object.keys(this.plateClassArray).map((key, index) => {
        return {
          value: index + 1,
          text:
            this.plateClassArray[key].plateClassCode +
            "-" +
            this.plateClassArray[key].plateClassDescription
        };
      });
    },
    csvData() {
      let data = {};
      switch (this.drillDownLevel) {
        case 0:
          data = this.classGrouping;
          break;
        case 1:
          if (this.orderedList == undefined || this.orderedList.length == 0) {
            data = this.subRangeList;
          } else {
            data = this.orderedList;
          }
          break;
        case 2:
          data = this.plateList.items;
          break;
      }
      return data;
    },
    csvFields() {
      const fields = [];
      for (let i = 0; i < this.tableFields[this.drillDownLevel].length; i++) {
        fields.push(this.tableFields[this.drillDownLevel][i].key);
      }
      return fields;
    },
    plateClassDecodeArray() {
      const temp = [];
      for (let i = 0; i < this.plateClassArray.length; i++) {
        temp[this.plateClassArray[i].plateClassCode] = this.plateClassArray[i];
      }
      return temp;
    },
    plateClassesForOrder() {
      const temp = {};
      const inventoryClasses = JSON.parse(
        JSON.stringify(this.$store.getters.inventoryPlateClasses)
      );
      for (let i = 0; i < inventoryClasses.length; i++) {
        if (!inventoryClasses[i].personalizedPlateInd)
          temp[inventoryClasses[i].plateClassCode] = inventoryClasses[i];
      }
      return temp;
    },
    isReceivingItem() {
      return ["S", "Y"].includes(this.assignmentModal.lineItem.statusCode);
    },
    validateMinMaxFields() {
      const fieldList = [
        this.minMaxInputs.min,
        this.minMaxInputs.max,
        this.minMaxInputs.plateClass
      ];
      for (let i = 0; i < fieldList.length; i++) {
        if (["", undefined].includes(fieldList[i])) {
          return false;
        }
      }
      return true;
    },
    validateOrderFields() {
      const fieldList = [
        this.orderInputs.plateClass,
        this.orderInputs.quantity
      ];
      for (let i = 0; i < fieldList.length; i++) {
        if (["", undefined].includes(fieldList[i])) {
          return false;
        }
      }
      return true;
    }
  }
};
</script>
<style scoped lang="scss">
.assignmentModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
}

.assignmentModal .flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.assignmentModal .flex-column.select {
  width: 200px;
}

.topModalSect > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.legendHold {
  display: flex;
  flex-direction: row;
}

.legend {
  min-width: 20px;
  min-height: 20px;
  margin-right: 5px;
  margin-left: 5px;
}

.export {
  justify-content: flex-end;
}
.table-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.export div {
  display: inline;
}

::v-deep .snackDiv {
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

::v-deep .snackDiv h2 {
  color: white;
  font-size: 16pt;
  width: auto;
  margin: 5px;
}

::v-deep .snackDiv div {
  font-size: 12pt;
  text-align: center;
}

#minMax {
  padding: 15px;
}

#minMax .inputDiv {
  max-width: 15%;
}

.progressBar {
  padding-top: 15px;
}
.progressBar div {
  width: 100%;
}
.buttonWithNum {
  width: 100%;
  text-align: center;
}

::v-deep .b-table tbody td {
  text-align: center;
}

::v-deep .v-btn.beside {
  margin-top: -8px;
  margin-right: 0px;
}

.invalid {
  border: 1px solid red;
}

::v-deep .b-table tbody tr:focus {
  background-color: var(--borders-backgrounds);
}

::v-deep .b-table-sticky-header > .table.b-table > thead > tr > th {
  z-index: 5;
}

::v-deep .receiving {
  background-color: #ffd9d9;
}

::v-deep .ordered {
  background-color: #fff9bf;
}

#quantityInput {
  margin-right: 5px;
}

.invisible {
  visibility: hidden;
}

.redText {
  color: red;
}

#inventory .green {
  background-color: green;
}

#separater {
  margin: 25px 10px 10px 10px;
}

.panel center label {
  display: inline;
}

#hrContain {
  width: 100%;
  margin-top: 20px;
}

#hrContain hr {
  margin: 0 !important;
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.inputDiv {
  max-width: 14%;
  margin-left: 20px;
}

.orderButton {
  margin-top: 20px;
}

.inputDiv select {
  margin-top: 0;
  width: 100%;
}

::v-deep .bread {
  color: var(--link-color);
}

::v-deep .v-btn {
  margin: 5px;
}

#resultsTable tr {
  cursor: pointer;
}
#resultsTable tr:hover {
  background-color: lightgray;
}
#resultsTable td {
  font-size: 12pt;
}
.btn-sm {
  padding: 6px;
  font-size: 12px;
}
.results {
  width: 100%;
}
.topForm {
  margin-left: 5%;
  width: 90%;
}
.first {
  width: auto;
  float: left;
  margin-right: 100px;
}
.second {
  width: 30%;
  float: left;
  margin-right: 100px;
}
.third {
  width: auto;
  float: left;
}
.topForm select {
  margin-bottom: 10px;
}
.topForm input {
  margin-bottom: 10px;
}
::v-deep .downloadBtn {
  padding: 16px 40px 16px 40px !important;
}
</style>
