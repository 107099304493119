var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"statusChange"}},[(!_vm.showEdit)?_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.statusResolve[_vm.currentStatus] || _vm.currentStatus)+" ")]),_c('v-tooltip',{attrs:{"open-delay":_vm.toolTipDelay,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"beside",attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();
            _vm.showEdit = true;}}},on),[_c('template',{slot:"default"},[_c('v-icon',[_vm._v("edit")])],1)],2)]}}],null,false,1672912676)},[_c('span',[_vm._v(" Change the status of this plate")])])],1):_vm._e(),_c('div',{class:{ invisible: !_vm.showEdit },on:{"click":function($event){return $event.stopPropagation()}}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedStatus),expression:"selectedStatus"}],on:{"click":function($event){return $event.stopPropagation()},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedStatus=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":_vm.currentStatus}},[_vm._v(" "+_vm._s(_vm.statusResolve[_vm.currentStatus] || _vm.currentStatus)+" ")]),_vm._l((_vm.options),function(value,key){return _c('option',{key:key,domProps:{"value":key}},[_vm._v(" "+_vm._s(value)+" ")])})],2),_c('v-tooltip',{attrs:{"open-delay":_vm.toolTipDelay,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.currentStatus == _vm.selectedStatus,"fab":"","small":"","color":"success"},on:{"click":function($event){$event.stopPropagation();
            _vm.saveClicked();
            _vm.showEdit = false;}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("done")])],1)]}}])},[_c('span',[_vm._v("Save the plate status change")])]),_c('v-tooltip',{attrs:{"open-delay":_vm.toolTipDelay,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();
            _vm.showEdit = false;}}},on),[_c('v-icon',[_vm._v("close")])],1)]}}])},[_c('span',[_vm._v("Close")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }